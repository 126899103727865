<template>
  <div id="app">
    <header>
      <div class="wrapper">
        <a href="http://www.empik.com/go" target="_blank"><img alt="Empik logo" src="./assets/empikgo-logo.png"></a>
      </div>
    </header>
    <section class="author-names">
      <span>Paweł <b>Majka</b></span>
      <span>Łukasz <b>Orbitowski</b></span>
      <span>Tobiasz <b>Piątkowski</b></span>
      <span>Robert <b>Szmidt</b></span>
      <span>Robert M. <b>Wegner</b></span> 
    </section>
    <Hero @scroll-to="scrollTo()" />
    <section class="hero-sub mobile">
      <div class="hero-sub__subtitle"><p>PRODUKCJA ORYGINALNA</p><img class="hero-sub__logo1" alt="Empik logo" src="./assets/empikgo-logo.png"></div>
      <a @click="scrollTo" target="_blank" class="button hero-sub__button1">SŁUCHAJ W <img class="hero-sub__logo2" alt="Empik logo" src="./assets/empikgo-logo.png"><span>*</span></a>
      <p class="hero-sub__try">* wypróbuj 7 dni za darmo</p>
      <AudioButton url="fragment.mp3" text="POSŁUCHAJ FRAGMENTU" class="hero-sub__button2" />
    </section>
    <section class="readers">
      Czytają: Krzysztof <b>Czeczot</b>, Mariusz <b>Bonaszewski</b>, Filip <b>Kosior</b>, Edyta <b>Olszówka</b>, Grzegorz <b>Pawlak</b>
    </section>
    <section class="description">
      <div class="wrapper">
        <div>Cyberpunkowa wizja Polski przyszłości: dystopijnej, rządzonej przez korporacje, w której społeczne rozwarstwienie prowadzi do walk między elitami i przestępczym półświatkiem. Czy postęp technologiczny pozwalający ludziom osiągać rzeczy dziś niemożliwe pozbawi ich człowieczeństwa?</div>
        <div class="more" :class="moreDescriptionVisible ? '' : 'hidden'">
          <p>„Antologia Polskiego Cyberpunka” to superprodukcja Empik Go z udziałem wybitnych polskich twórców gatunku science fiction – Pawła Majki, Łukasza Orbitowskiego, Tobiasza Piątkowskiego, Roberta J. Szmidta i Roberta Wegnera.</p>
          <p>Muzykę skomponował Steez83 z zespołu PRO8L3M, a za oprawę graficzną odpowiada ilustrator Jakub Rebelka. „Antologia” dostępna jest w formie superprodukcji audio i e-booka.</p>
        </div>
        <div class="read-more" :class="moreDescriptionVisible ? 'active' : ''" @click="moreDescriptionVisible = !moreDescriptionVisible">
          Czytaj {{moreDescriptionVisible ? 'mniej' : 'więcej'}}<img alt="czytaj więcej" src="./assets/more.png">
        </div>
        <div class="description__premiere">Premiera: 10/11/2020</div>
      </div>
    </section>
    <section class="authors">
      <img alt="authors header" src="./assets/tworcy.png" />
      <p>Poznaj autorów pierwszej Antologii Polskiego Cyberpunka:</p>
      <div class="authors__grid1">
        <div
          class="author"
          v-for="author in (authors.filter(author => !author.other))"
          :key="author.lastName"
          @click="showAuthor(author)"
        >
          <img :src="require(`@/assets/${author.img}`)" />
          <div class="author__bottom">
            <p>{{author.lastName}}</p>
          </div>
          <div class="author__overlay">
            <p>{{author.firstName}}</p>
            <p><b>{{author.lastName}}</b></p>
          </div>
        </div>
      </div>
      <div class="authors__grid2">
        <div
          class="author"
          v-for="author in (authors.filter(author => author.other))"
          :key="author.lastName"
          @click="showAuthor(author)"
        >
          <img :src="require(`@/assets/${author.img}`)" />
          <div class="author__bottom">
            <p>{{author.lastName}}</p>
          </div>
          <div class="author__overlay">
            <p>{{author.firstName}}</p>
            <p><b>{{author.lastName}}</b></p>
          </div>
        </div>
      </div>
    </section>
    <section class="devices" ref="devices">
      <div class="wrapper">
        <div class="devices__left">
          <img class="devices__logo" alt="Empik logo" src="./assets/empikgo-logo.png">
          <h2>Niesamowite historie w każdej chwili</h2>
          <p class="devices__description">Słuchaj "Antologii Polskiego Cyberpunka" w aplikacji Empik Go. Wybieraj też spośród ponad 13 tys. audiobooków i podcastów oraz 30 tys e-booków. Wolisz czytać? "Antologia Polskiego Cyberpunka" dostępna jest również jako e-book.</p>
          <a class="button" target="_blank" href="https://www.empik.com/go/cennik">WYPRÓBUJ BEZPŁATNIE PRZEZ 7 DNI*</a>
          <p class="devices__subdescription">* Potem płacisz jedynie 39,99 zł miesięcznie. Zarejestruj się i rozpocznij 7-dniowy okres próbny. Anuluj w dowolnym momencie.</p>
          <p>Masz kod promocyjny? Zrealizuj go <a href="https://www.empik.com/gofree" target="_blank">tutaj!</a></p>
        </div>
        <div class="devices__right">
          <img alt="Urządzenia" src="./assets/mobiles.png" />
        </div>
        <div class="devices__bottom">
          <a class="button" target="_blank" href="https://www.empik.com/go/cennik">WYPRÓBUJ BEZPŁATNIE PRZEZ 7 DNI*</a>
          <p class="devices__subdescription">* Potem płacisz jedynie 39,99 zł miesięcznie. Zarejestruj się i rozpocznij 7-dniowy okres próbny. Anuluj w dowolnym momencie.</p>
          <p>Masz kod promocyjny? Zrealizuj go <a href="https://www.empik.com/gofree" target="_blank">tutaj!</a></p>
        </div>
      </div>
    </section>
    <section class="reading">
      <img alt="Czytają" src="./assets/czytaja.png" />
      <div class="reading__carousel">
        <div class="reading__grid">
          <div
            v-for="(reader, index) in readers"
            :key="reader.lastName + index"
            class="grid-item"
          >
            <img :src="require(`@/assets/${reader.img}`)" />
            <div class="grid-item__overlay">
              <p>{{reader.firstName}}</p>
              <p>{{reader.lastName}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="realization">
      <img alt="Realizacja" src="./assets/realizacja.png" />
      <p>Czytają: Edyta Olszówka, Mariusz Bonaszewski, Krzysztof Czeczot, Filip Kosior i Grzegorz Pawlak.</p>
      <p>Realizacja dźwięku:<br />Maciej Zych, Jan Jędrzejczyk, Agata Lenarczyk i Wojciech Pątkiewicz.</p>
      <div class="more" :class="moreRealizationVisible ? '' : 'hidden'">
        <p>W realizacji wykorzystano zasoby biblioteki Audionetwork.<br />Reżyseria: Krzysztof Czeczot. </p>
        <p>Producent: Paweł Heba<br />Producent wykonawczy Osorno Media<br />Kierownictwo produkcji Patryk Makowski<br />Copyright Empik S.A.</p>
      </div>
      <div class="read-more" :class="moreRealizationVisible ? 'active' : ''" @click="moreRealizationVisible = !moreRealizationVisible">
        Czytaj {{moreRealizationVisible ? 'mniej' : 'więcej'}}<img alt="czytaj więcej" src="./assets/more.png">
      </div>
    </section>
    <section class="productions">
      <div class="productions__left"><p>zobacz inne</p><p>nasze produkcje</p></div>
      <div class="productions__right">
        <a href="http://www.empik.com/chaos" target="_blank"><img alt="CHAOS" src="./assets/chaos.png" /></a>
        <a href="http://www.empik.com/fucking-bornholm" target="_blank"><img alt="Lalka" src="./assets/fucking.png" /></a>
        <a href="https://www.empik.com/1-kroniki-koronawirusowe-malcolm-xd,p1240769980,ebooki-i-mp3-p" target="_blank"><img alt="Lalka" src="./assets/malcolm.png" /></a>
        <a href="https://www.empik.com/wyrwa-historia-ulana" target="_blank"><img alt="Wyrwa" src="./assets/wyrwa.png" /></a>
      </div>
    </section>
    <footer>
      <div class="wrapper">
        <div class="contact">
          <img src="./assets/call.png" alt="Zadzwoń" />
          <div class="contact__support">Centrum Wsparcia<br />Klienta</div>
          <div class="contact__divider" />
          <div class="contact__call-number"><b>+48 22 462 72 50</b><br />Czynne całą dobę</div>
          <div class="contact__divider" />
          <div class="contact__press"><a href="https://news.empik.com/search?utf8=%25E2%259C%2593&amp;button=&amp;q=empik+go" target="_blank">Biuro Prasowe<br />Empik Go</a></div>
        </div>
        <div class="social">
          <div>
            <a href="https://www.facebook.com/EmpikGo/" target="_blank">
              <img src="./assets/facebook-logo.png" />
            </a>
            <a href="https://www.instagram.com/empikgo/" target="_blank">
              <img src="./assets/instagram.png" />
            </a>
            <a href="https://www.youtube.com/channel/UCS324MkW7mNjFqyFE_n313A" target="_blank">
              <img src="./assets/youtube.png" />
            </a>
          </div>
          <div>
            <a href="http://www.empik.com/go" target="_blank">
              <img src="./assets/empikgo-color-logo.png" />
            </a>
          </div>
        </div>
      </div>
    </footer>
    <div class="popups" :class="popup.open ? 'popups--visible' : ''">
      <div class="popups__overlay" @click="closePopup()" />
      <div class="popup">
        <div class="popup__left">
          <img v-if="popup.img" :src="require(`@/assets/${popup.img}`)" />
        </div>
        <div class="popup__right">
          <div class="popup__close" @click="closePopup()" />
          <h3>{{popup.title1}}</h3>
          <p>{{popup.description1}}</p>
          <h3 v-if="popup.title2">{{popup.title2}}</h3>
          <p v-if="popup.description2">{{popup.description2}}</p>
          <div v-if="popup.audios" class="popup__audios">
            <AudioButton
              v-for="audio in popup.audios"
              :key="audio.url"
              :url="audio.url"
              :text="audio.name"
              icon2
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioButton from '@/components/AudioButton'
import Hero from '@/components/Hero'

window.audios = [];

export default {
  name: 'App',
  components: {
    AudioButton,
    Hero,
  },
  data() {
    return {
      moreDescriptionVisible: false,
      moreRealizationVisible: false,
      authors: [
        { firstName: 'Paweł', lastName: 'Majka', img: 'majka.png', img2: 'majka2.png',
          title1: 'Paweł Majka', description1: 'Paweł Majka, urodził się 2 czerwca 1972 roku w Krakowie. Swoje opowiadania i teksty publikował w licznych czasopismach, antologiach i magazynach internetowych. W 2014 roku ukazała się jego pierwsza powieść, „Pokój Światów”. Od tamtego czasu wydał dziesięć powieści, w tym jedną wspólnie z Michałem Cetnarowskim a dwie z Radkiem Rusakiem. Czterokrotnie nominowany był do Nagrody im. Janusza. Trzykrotnie zdobył Nagrodę im. Jerzego Żuławskiego. „Pokój Światów” i „Niebiańskie Pastwiska” otrzymały nagrodę główną zaś „Wojny Przestrzeni” zdobyło srebrne wyróżnienie.',
          title2: 'Opowiadanie „Pastuch”', description2: 'W świecie przyszłości, po pandemii, ludzie uciekli od natury w świat technologii. Porządku pilnuje tu Smocza Husaria, wyposażona w nowoczesne bojowe pancerze. Bohaterami opowiadania są Michał i Wilku, chorąży Husarii, którzy zostają wezwani by walczyć w Gorcach z tytułowym Pastuchem.',
        },
        { firstName: 'Łukasz', lastName: 'Orbitowski', img: 'orbitowski.png', img2: 'orbitowski2.png',
          title1: 'Łukasz Orbitowski', description1: 'Łukasz Orbitowski (ur 1977), pisarz, autor m.in. powieści “Widma”, "Inna dusza" i "Kult". Laureat Paszportu Polityki, trzykrotnie nominowany do Nike. Dawniej autor programów telewizyjnych, dziś podcastu "Metale szlachetne". Zbiera nosorożce, podnosi ciężary. Mieszka z rodziną w Krakowie.',
          title2: 'Opowiadanie „1990”', description2: 'Kraków, początek lat ’90. Paczka przyjaciół spędza lato na tym, co człowiek lubi robić najbardziej w wolnym czasie kiedy jest nastolatkiem w latach ’90 – graniu na fliperach, wycieczkach rowerowych i… znęcaniu się nad nielubianym kolegą ze szkoły. Pewnego dnia jednak role się odwracają, a chłopaki z prześladowców stają się ofiarami. Co się stało, że nieudacznik, któremu wcześniej dokuczali, zyskał nadludzkie moce i zaczął wyżywać się na nich?',
        },
        { firstName: 'Tobiasz', lastName: 'Piątkowski', img: 'piatkowski.png', img2: 'piatkowski2.png',
          title1: 'Tobiasz Piątkowski', description1: 'Tobiasz Piątkowski, urodzony w roku 1979. Absolwent Wydziału Architektury Politechniki Warszawskiej. Scenarzysta ponad dwudziestu albumów komiksowych (“Bradl”, “Biegun”, “Pierwsza Brygada”, “Status 7”), licznych komiksów prasowych (seria “48 stron”) i dziecięcych (“Opowieści biblijne”). Laureat nagrody Koraka (Interkomix) dla najlepszego scenarzysty (2003), Nagrody dla Najlepszego Polskiego Albumu Komiksowego MFKiG (2008).  Pracuje jako projektant i art director.',
          title2: 'Opowiadanie „Śmierć Łucznika”', description2: 'Do Polski po kilkunastu latach nieobecności przylatuje z Kalifornii Rania Starecka, zatrudniona jako Wynalazca w amerykańskiej korporacji. Na Lotnisku dzięki precyzyjnie dobranym emanacjom cyfrowym dynamicznie zmienia swoje tożsamości i przy pomocy autonomicznego Peugeota dostaje się do Radomia, gdzie przed opuszczeniem Polski mieszkała z rodziną jako mała dziewczynka. To właśnie tu wyrósł Sprawl, jedna z 18 gigantycznych konstrukcji. Czym jest Sprawl? I dlaczego Rania się nim interesuje? Piątkowski przedstawia Polskę przyszłości z autonomicznymi pojazdami, techno metropoliami i obywatelami, których certyfikat sanitarny można zweryfikować w każdej chwili.',
        },
        { firstName: 'Robert', lastName: 'Szmidt', img: 'szmidt.png', img2: 'szmidt2.png',
          title1: 'Robert J. Szmidt', description1: 'Robert J. Szmidt (ur. 1962). Twórca i redaktor naczelny miesięcznika “Science Fiction” (od 2005 roku „Science Fiction, Fantasy i Horror”), na którego łamach zadebiutowała większość znaczących pisarzy fantastycznych ostatniego pokolenia. Założyciel portalu www.fantastykapolska.pl. Autor dziewiętnastu powieści oraz ponad dwudziestu opowiadań. Tłumacz ponad osiemdziesięciu książek i jedenastu gier komputerowych. Serial audio „Chaos” na motywach „Szczurów Wrocławia” nominowano w tym roku do nagrody Bestsellery Empiku.',
          title2: 'Opowiadanie „Śmierć to nie wszystko”', description2: 'Beryl Smith, młoda korpolicjantka wpada przypadkiem na trop afery, ktora wykracza daleko poza jej kompetencje. Znalezione na wysypisku szczątki androida kryją zagadkę, która może ją kosztować więcej, niż tylko życie.',
        },
        { firstName: 'Robert M.', lastName: 'Wegner', img: 'wegner.png', img2: 'wegner2.png',
          title1: 'Robert M. Wegner', description1: 'Autor opowiadań i powieści fantastycznych na stałe mieszkający i pracujący na Śląsku. Debiutował w 2002 roku w miesięczniku Science Fiction opowiadaniem „Ostatni lot Nocnego Kowboja” a pierwszą książkę wydał w 2009 roku w wydawnictwie Powergraph, z którym jest związany do dziś. Znany najbardziej z cyklu fantasy zatytułowanego „Opowieści z meekhańskiego pogranicza”, choć ma na koncie także opowiadania SF. Laureat licznych nagród literackich. Jego książki są tłumaczone na język czeski, rosyjski i ukraiński. Mąż wspaniałej kobiety i ojciec dwóch niesamowitych córek.',
          title2: 'Opowiadanie „Serce strzygi”', description2: 'Czy strzygi, dybuki, poltergeisty i inne duchy mogą nadal uprzykrzać życie mieszkańcom krakowskich domów w odległej przyszłości, gdy świat zdominowany jest przez nowoczesne technologie? Okazuje się, że jak najbardziej – a owe demony to nic innego jak systemy operacyjne domów lub narzędzi, które uległy awarii i naśladują nawyki swoich byłych właścicieli. Główny bohater opowiadania „Serce strzygi” jest zatrudnionym przez firmę Ghosthunters specjalistą do spraw usuwania takich niechcianych lokatorów. Przed nim właśnie kolejne, nudne wydawałoby się zadanie egzorcyzmowe…',
        },
        { firstName: 'Jakub', lastName: 'Rebelka', img: 'rebelka.png', img2: 'rebelka2.png', other: true,
          title1: 'Jakub Rebelka', description1: 'Urodzony w 1981 roku ilustrator, autor komiksów, malarz. Autor serii komiksów o “Doktorze Bryanie” oraz “Ester Klemens”, publikował w wielu polskich magazynach. Ma na koncie współprace z prestiżowymi wydawnictwami komiksowymi, w tym z Marvelem. Wspólnie ze scenarzystą Yohanem Radomskim wydał we Francji komiks "La Cite des Chiens " (polski przekład 2016 rok “Miasto Psów"). Dla wydawnictwa BOOM!Studio wydał w USA serię komiksów “Namesake” wraz ze scenarzystą Stevem Orlando oraz serię “Judas” z Jeffem Lovenese. Jest autorem wielu projektów dla branży gier komputerowych i RPG.',
        },
        { firstName: 'Piotr', lastName: 'Steez83', img: 'steez.png', img2: 'steez2.png', other: true,
          title1: 'Steez83, a właściwie Piotr', description1: 'Producent muzyczny, DJ, kreator sceny, wydawca, producent wydarzeń i twórca nowych form biznesu muzycznego. Zaczynał od DJ\'ingu i fascynacji turntablismem w latach 2000. Artystycznie Steez reprezentuje połowę rapowego duetu PRO8L3M, który jako fenomen polskiej sceny od lat wytycza nowe ścieżki dla rozwoju kreacji i narracji w muzyce oraz przyciąga tysiące odbiorców na koncerty. Jako producent muzyczny ma na koncie wiele sukcesów, a w tym kilkanaście platynowych i złotych płyt, Fryderyka – nagrodę ZPAV - Polskiej Akademii Fonograficznej oraz nagrodę European Border Breakers Award, wyróżnienie Unii Europejskiej. W setach DJ’skich Steez podróżuje po nowoczesnych brzmieniach klubowych, nawiązując do swoich rapowych korzeni i zainteresowań muzyką elektroniczną. Prowadzi także w Newonce Radio autorską audycję pt. „Audiencja”, w ramach której dzieli się ze słuchaczami swoimi zajawkami muzycznymi. Swoje życie artystyczne i prywatne dzieli pomiędzy Warszawą a Los Angeles.',
          audios: [
            { name: 'Gods Hack', url: 'EXIT_STEEZ_Gods Hack_MIXMD_V.03.mp3' },
            { name: 'interstellar', url: 'EXIT_STEEZ_Interstellar_MIXMD_V.03.mp3' },
            { name: 'Kusanagis', url: 'EXIT_STEEZ_Kusanagis_MIXMD_V.02.mp3' },
          ],
        },
      ],
      readers: [
        { firstName: 'Krzysztof', lastName: 'Czeczot', img: '1.jpg'},
        { firstName: 'Filip', lastName: 'Kosior', img: '2.jpg'},
        { firstName: 'Mariusz', lastName: 'Bonaszewski', img: '3.jpg'},
        { firstName: 'Grzegorz', lastName: 'Pawlak', img: '4.jpg'},
        { firstName: 'Edyta', lastName: 'Olszówka', img: '5.jpg'},
        { firstName: 'Krzysztof', lastName: 'Czeczot', img: '1.jpg'},
        { firstName: 'Filip', lastName: 'Kosior', img: '2.jpg'},
        { firstName: 'Mariusz', lastName: 'Bonaszewski', img: '3.jpg'},
        { firstName: 'Grzegorz', lastName: 'Pawlak', img: '4.jpg'},
        { firstName: 'Edyta', lastName: 'Olszówka', img: '5.jpg'},
      ],
      popup: {
        open: false,
        img: '',
        title1: '',
        description1: '',
        title2: '',
        description2: '',
      },
    }
  },
  methods: {
    scrollTo: function() {
      this.$refs['devices'].scrollIntoView({behavior: "smooth", block: "start"});
    },
    showAuthor: function(author) {
      this.popup = {
        open: true,
        img: author.img2,
        title1: author.title1,
        description1: author.description1,
        title2: author.title2,
        description2: author.description2,
        audios: author.audios,
      }
    },
    closePopup: function() {
      this.popup = {
        open: false,
        img: null,
        title1: '',
        description1: '',
        title2: '',
        description2: '',
        audios: null,
      }
    }
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import 'reset-css';

$width: 1140px;
$t: 0.2s ease-in-out;

*,
*::before,
*::after {
  box-sizing: border-box;
}

img, a {
  display: inline-block;
}

b {
  font-weight: 700;
}

a {
  cursor: pointer;
  color: #fff;
  transition: color $t;

  &:hover {
    color: #5eace0;
  }
}

.mobile {
  display: none;
}

.more {
  transition: max-height 0.5s ease-in-out;
  max-height: 55vh;
  overflow: hidden;
  padding: 0;

  &.hidden {
    max-height: 0;
  }
}

.read-more {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;

  > img {
    margin-left: 15px;
    transition: transform 0.5s ease-in-out;
  }

  &.active {
    > img {
      transform: scaleY(-1);
    }
  }
}

.popups {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  transition: opacity $t;

  &__overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #0d2f45;
    opacity: 0.87;
  }

  &--visible {
    opacity: 1;
    pointer-events: all;
  }
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 1154px;
  display: flex;

  &__left {
    flex: 1 1 31%;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__right {
    flex: 1 1 69%;
    background: rgba(255, 255, 255, 0.5);
    color: #353535;
    text-align: left;
    padding: 52px 56px 52px;

    h3 {
      font-size: 30px;
      padding-bottom: 12px;
    }

    p {
      padding-bottom: 12px;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    background: #b51411;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: background $t;

    &::before {
      content: 'X';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: bold;
    }

    &:hover {
      background: #d53936;
    }
  }

  &__audios {
    .audio-button.button {
      background: transparent;
      margin-right: 12px;

      p {
        padding: 0;
      }
    }
  }
}

#app {
  font-family: 'Lato', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  font-size: 18px;

  header {
    background: #000;
    height: 99px;
    display: flex;
    align-items: center;
    text-align: left;

    img {
      width: 228px;
      height: auto;
    }
  }

  .wrapper {
    width: 100%;
    max-width: $width;
    margin: 0 auto;
    padding: 0 20px;
  }

  .button {
    background: #000;
    border-radius: 25px;
    color: #fff;
    padding: 19px 36px;
    text-decoration: none;
    font-size: 13px;
    letter-spacing: 0.33px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
  }

  .author-names {
    background: #081e2c;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      padding: 30px 0;
      font-size: 18px;
      text-transform: uppercase;

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  .hero {
    background: url('./assets/bg-top.png');
    background-size: cover;
    position: relative;
    overflow: hidden;

    &__wrapper {
      max-width: $width;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }

    &__left {
      flex: 1 1 60%;
      padding-top: 150px;
      padding-bottom: 74px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 10;
    }

    &__title {
      max-width: 609px;
    }

    &__logo, &__title {
      width: 100%;
      height: auto;
    }

    &__subtitle {
      display: flex;
      align-items: center;
      padding: 13px 0 30px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.45px;

      > img {
        width: 122px;
        height: auto;
        margin-left: 9px;
        margin-top: 2px;
      }
    }

    &__button1.button {
      background: #0c0a1c;
      padding: 15px 30px 17px;
      margin-bottom: 5px;
      border: 2px solid rgba(255,255,255,0);
      transition: border $t;

      img {
        width: 89px;
        height: auto;
        margin-left: 10px;
        margin-bottom: -3px;
        margin-right: 4px;
      }

      span {
        margin-top: -6px;
      }

      &:hover {
        border: 2px solid rgba(255,255,255,1);
      }
    }
    
    &__try {
      font-size: 14px;
    }

    &__button2.button {
      background: transparent;
      border: 2px solid #fff;
      margin-top: 27px;
      transition: background $t;

      img {
        width: 12px;
        height: auto;
        margin-right: 10px;
      }

      &:hover {
        background: #0c0a1c;
      }

      .audio-button__progress {
        background: #000;
      }
    }

    &__right {
      flex: 1 1 40%;
    }

    &__character {
      width: auto;
      height: 121%;
      position: absolute;
      bottom: 0;
      left: 40%;
      z-index: 5;
    }
  }

  .readers {
    text-align: center;
    background: #081e2c;
    padding: 20px 17px;
  }

  .description {
    background-color: #0d2f45;

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;

      .more p {
        padding: 20px;
      }

      > div:not(.more) {
        padding: 20px;
      }
    }

    &__premiere {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .authors {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0f3650;
    text-transform: uppercase;

    > img {
      margin-top: 36px;
      margin-bottom: 28px;
    }

    .author {
      cursor: pointer;
      position: relative;
      margin-right: 3%;
      width: 16%;
      max-width: 200px;
      height: 16%;
      max-height: 200px;

      img {
        width: 100%;
        height: 100%;
      }

      &__bottom {
        position: absolute;
        bottom: 0;
        height: 32px;
        width: 100%;
        background: rgba(10, 23, 65, 0.73);
        transition: opacity $t;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__overlay {
        position: absolute;
        top: 0;
        background: rgba(10, 23, 65, 0.73);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        opacity: 0;
        transition: opacity $t;

        p:first-child {
          font-size: 14px;
          font-weight: bold;
        }

        p:last-child {
          font-size: 23.5px;
          font-weight: bold;
          line-height: 35px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        .author__overlay {
          opacity: 1;
        }

        .author__bottom {
          opacity: 0;
        }
      }
    }

    &__grid1 {
      width: 100%;
      margin-top: 56px;
      margin-bottom: 33px;
      display: flex;
      justify-content: center;
    }

    &__grid2 {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom:33px;

      .author {
        &__bottom {
          background: rgb(97, 8, 18, 0.73);
        }
        
        &__overlay {
          background: rgb(97, 8, 18, 0.73);
        }
      }
    }
  }

  .devices {
    background: #113c57;
    padding: 54px 0 152px;

    .wrapper {
      display: flex;
    }

    &__left {
      flex: 1 1 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

      h2 {
        font-size: 33px;
      }
    }

    &__description {
      font-size: 22px;
      margin-top: 36px;
      margin-bottom: 68px;
    }

    &__subdescription {
      color: #717171;
      font-size: 10px;
      letter-spacing: 0.25px;
      width: 240px;
      margin: 13px 0 33px;
    }

    &__logo {
      width: 372px;
      height: 100px;
      margin-top: 112px;
      margin-bottom: 5px;
    }

    &__right {
      flex: 1 1 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__bottom {
      display: none;
    }
  }

  .reading {
    background-color: #123f5c;
    padding-bottom: 50px;

    > img {
      margin: 29px 0 42px;
    }

    &__carousel {
      max-width: 100%;
      overflow: hidden;
      position: relative;
    }

    &__grid {
      display: flex;
      position: relative;
      animation: carousel 30s infinite;
      animation-timing-function: linear;
      animation-play-state: running;

      &:hover {
        animation-play-state: paused;
      }
    }

    @keyframes carousel {
      0% {left: 0px;}
      100% {left: -2715px;}
    }

    .grid-item {
      position: relative;
      margin-right: 60px;

      > img {
        display: block;
      }

      &__overlay {
        position: absolute;
        top: 0;
        background: rgba(10, 23, 65, 0.73);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        opacity: 0;
        transition: opacity $t;

        p:first-child {
          font-size: 20px;
        }

        p:last-child {
          font-size: 32px;
          margin-top: 5px;
        }
      }

      &:hover {
        .grid-item__overlay {
          opacity: 1;
        }
      }
    }
  }

  .realization {
    background-color: #15496b;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 85px;

    > img {
      margin: 61px 0 32px;
    }

    p {
      margin-bottom: 28px;
      line-height: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .more {
      max-height: 360px;

      &.hidden {
        max-height: 0;
      }
    }

    .more p {
      margin-bottom: 28px;
    }
  }

  .productions {
    background: #16547d;
    display: flex;
    height: 123px;

    &__left {
      flex: 1 1 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      background: #144e74;
      font-size: 24px;
      line-height: 30px;
      text-align: right;
      text-transform: uppercase;
      padding-right: 43px;

      p {
        width: 100%;
      }
    }

    &__right {
      flex: 1 1 70%;
      display: flex;
      padding-right: 6px;

      a {
        margin-left: 54px;
        position: relative;
        top: -45px;
        width: 15%;
        max-width: 150px;
        height: auto;
        max-height: 150px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  footer {
    background: #000;
    color: #aca7a8;

    .wrapper {
      display: flex;
      align-items: center;
      padding: 32px 0 25px;
    }

    b {
      color: #fff;
    }

    .contact {
      flex: 1 0 60%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 22px;
      font-weight: bold;

      img {
        width: 66px;
        height: auto;
      }

      > div {
        margin: 0 15px;

        &:nth-child(2) {
          margin-left: 0;
        }
      }

      &__support {
        text-align: right;
      }

      &__divider {
          width: 4px;
          height: 56px;
          border: solid 2px #aca7a8;
          background-color: #737373;
      }

      &__call-number {
        text-align: center;
      }

      &__press {
        text-align: left;
      }
    }

    .social {
      flex: 1 1 40%;
      display: flex;
      align-items: center;

      div {
        flex: 1 1 50%;
      }

      > div:first-child {
        display: flex;
        justify-content: space-evenly;

        a {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {

          }
        }
      }
    }
  }

  .popups {
    .button.audio-button {
      border: 2px solid #353535;
      background: transparent;
      color: #353535;
      padding: 14px 20px;
      text-transform: uppercase;
      font-size: 11px;
    }
    .audio-button__progress {
      background: #fff;
    }
  }

  @media (max-width: 900px) {
    .mobile {
      display: block;
    }

    header {
      .wrapper {
        text-align: center;
      }
    }

    .author-names {
      flex-wrap: wrap;
      padding: 29px 60px;

      span {
        padding: 0;
        display: flex;
        line-height: 22px;

        > b {
          margin-left: 5px;
        }
      }
    }

    .hero {
      height: 100vh;
      max-height: 600px;

      .wrapper {
        height: 100%;
        overflow: hidden;
        position: relative;
      }

      &__left {
        position: relative;
        z-index: 10;
        padding-top: 46px;

        img {
          width: 87%;
          height: auto;
        }

        > :not(img) {
          display: none;
        }
      }

      &__right {
        position: absolute; 
        z-index: 5;
        height: 116%;
        bottom: 0;
        left: -230px;

        img {
          width: auto;
          height: 100%;
        }
      }
    }

    .hero-sub {
      background: #081e2c;

      &__logo1, &__logo2 {
        width: 100%;
        height: auto;
      }

      &__subtitle {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 0 30px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.45px;

        > img {
          width: 122px;
          height: auto;
          margin-left: 9px;
          margin-top: 2px;
        }
      }

      &__button1.button {
        background: #0c0a1c;
        padding: 15px 30px 17px;
        margin-bottom: 5px;
        border: 2px solid rgba(255,255,255,0);
        transition: border $t;

        img {
          width: 89px;
          height: auto;
          margin-left: 10px;
          margin-bottom: -3px;
          margin-right: 4px;
        }

        span {
          margin-top: -6px;
        }

        &:hover {
          border: 2px solid rgba(255,255,255,1);
        }
      }
      
      &__try {
        font-size: 14px;
      }

      &__button2.button {
        background: transparent;
        border: 2px solid #fff;
        margin-top: 27px;
        transition: background $t;

        img {
          width: 12px;
          height: auto;
          margin-right: 10px;
        }

        &:hover {
          background: #0c0a1c;
        }

        .audio-button__progress {
          background: #000;
        }
      }
    }

    .devices {
      padding: 0 0 40px;

      .wrapper {
        flex-direction: column;
        padding: 0 30px;
        max-width: 75%;
        margin: 0;
      }

      &__left {
        img {
          width: 157px;
          height: auto;
          margin: 32px 0 5px;
        }

        h2 {
          font-size: 22px;
        }

        .devices__description {
          font-size: 14.5px;
          margin: 24px 0 22px;
        }

        > :nth-child(n + 4) {
          display: none;
        }
      }

      &__right img {
        max-width: 400px;
        width: 100%;
        height: auto;
      }

      &__bottom {
        display: block;
        text-align: left;
      }
    }

    .productions {
      flex-wrap: wrap;
      height: auto;

      &__left, &__right {
        flex: 1 1 100%;
      }

      &__left {
        padding: 35px 0;
        justify-content: center;

        p {
          width: auto;
        }

        p:first-child {
          margin-right: 0;
        }
      }

      &__right {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 (30px/480px) * 100%;

        a {
          width: (150px/480px) * 100%;
          height: auto;
          margin: (30px/480px) * 100%;
          position: static;
        }
      }
    }

    footer {
      .wrapper {
        flex-wrap: wrap;
        padding: 26px 0;

        .contact, .social {
          flex: 1 1 100%;
        }

        .contact {
          justify-content: center;

          img {
            width: 43px;
            height: 43px;
          }

          > div {
            font-size: 11.5px;
            letter-spacing: 0.29px;
            line-height: 13px;
            margin: 0 10px;

            &:nth-child(2) {
              margin-left: 0;
              flex-shrink: 1;
            }

            &:nth-child(4) {
              flex-grow: 1;
            }
          }

          &__divider {
            height: 37px;
          }
        }

        .social {
          padding-top: 32px;
          justify-content: flex-end;
          max-width: 650px;
          margin:0 auto;

          > div {
            flex: 0 1 50%;
          }

          div:first-child {
            justify-content: center;

            a {
              margin-right: 14px;

              &:last-child {
                margin-right: 0;
              }

              img {
                width: 24px;
                height: auto;
              }
            }
          }
        }
      }
    }

    .popup {
      &__left {
        display: none;
      }

      &__right {
        flex: 1 1 100%;
        padding: 13px 14px 13px;
        max-height: 100vh;
        overflow-y: auto;

        h3 {
          font-size: 22px;
        }

        p {
          font-size: 14px;
        }
      }

      &__close {
        width: 40px;
        height: 40px;
      }

      &__audios {
        .button.audio-button {
          padding: 10px 20px;
          margin-bottom: 12px;
        }
      }
    }

    .authors {
      > p {
        padding: 0 20px;
      }

      &__grid1, &__grid2 {
        flex-wrap: wrap;
        padding: 0 5%;
      }

      .author {
        width: 40%;
        max-width: none;
        height: 40%;
        max-height: none;
        margin-bottom: 2%;

        &:nth-child(2n + 1) {
          margin-right: 5%;
        }

        &:nth-child(2n), &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  
  @media (max-width: 550px) {
    .author-names {
      padding: 29px 0;
    }
  }

  @media (max-width: 480px) {
    .hero-sub {
      &__subtitle {
        display: block;

        img {
          width: 122px;
          height: auto;
          margin: 10px 0 0;
        }
      }
    }
  }

  @media (max-width: 450px) {
    .devices {
      .wrapper {
        max-width: 100%;
      }
    }

    .productions {
      &__left {
        text-align: center;
        flex-wrap: wrap;

        p {
          width: 100%;

          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .author-names {
      > span {
        text-align: center;
        flex: 1 1 100%;
        display: block;

        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 2px;
        }
      }
    }
  }
}
</style>
