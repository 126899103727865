<template>
  <div @click="playPause()" class="button audio-button">
    <div class="audio-button__progress" :style="{width: (progress * 100) + '%' }" />
    <img v-if="!playing" alt="play" :src="require(`@/assets/play-icon${icon2 ? '2' : ''}.png`)">
    <img v-if="playing" alt="pause" :src="require(`@/assets/pause-icon${icon2 ? '2' : ''}.png`)">
    <p>{{text}}</p>
    <audio ref="audio">
      <source :src="require(`@/assets/audio/${url}`)" type="audio/mpeg">
    </audio>
  </div>
</template>

<script>
export default {
  name: 'AudioButton',
  props: {
    url: String,
    text: String,
    icon2: Boolean,
  },
  data() {
    return {
      playing: false,
      progress: 0,
    }
  },
  methods: {
    playPause: function() {
      if (this.playing) {
        this.$refs.audio.pause();
      } else {
        window.audios.forEach(a => a.ref.pause());
        this.$refs.audio.play();
      }
    },
  },
  mounted() {
    if (!window.audios.find(a => a.ref === this.$refs.audio)) {
      window.audios.push({ url: this.url, ref: this.$refs.audio });
    }
    this.$refs.audio.onpause = () => {
      this.playing = false;
    };
    this.$refs.audio.ontimeupdate = () => {
      this.progress = this.$refs.audio.currentTime / this.$refs.audio.duration;
      this.playing = true;
    };
    this.$refs.audio.onended = () => {
      this.progress = 0;
      this.playing = false;
    }
  }
}
</script>

<style lang="scss">
.audio-button {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  img {
    margin-top: -2px;
    margin-right: 10px;
    width: 10px;
    height: 11px;
  }

  &__progress {
    position: absolute;
    left: 0;
    width: 0%;
    height: 100%;
    background: red;
    z-index: 5;
  }

  > :not(.audio-button__progress) {
    position: relative;
    z-index: 10;
  }
}
</style>